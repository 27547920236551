export function getTerminalKeys(obj, path = "") {
  if (obj === null) {
    return [];
  }

  return Object.keys(obj).reduce((accumulator, key) => {
    const value = obj[key];
    const currentPath = path ? `${path}.${key}` : key;
    if (typeof value === "object") {
      return accumulator.concat(getTerminalKeys(value, currentPath));
    }

    return accumulator.concat([currentPath]);
  }, []);
}
