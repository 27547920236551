import React from "react";
import { useTranslation } from "react-i18next";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import Button, { TextButton } from "components/ui/Button";
import { FieldWrap } from "components/styled/Field";

const FieldsWrapper = styled.div`
  > :first-child {
    > ${FieldWrap} {
      margin-top: 0;
    }
  }

  > :last-child {
    > ${FieldWrap} {
      margin-bottom: 0;
    }
  }
`;

const MultiInputsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

const RemoveFieldButton = styled(TextButton)`
  font-size: 15px;
  margin-left: 15px;
`;

const AddFieldButton = styled(Button)`
  margin-top: 23px;
  margin-left: 15px;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  > ${FieldWrap} {
    width: 428px;
  }

  > ${RemoveFieldButton} {
    margin-top: 26px;
  }

  :first-child {
    > ${RemoveFieldButton} {
      margin-top: 6px;
    }

    > ${AddFieldButton} {
      margin-top: 2px;
    }
  }
`;

function MultipleFields(Component) {
  function FieldsComponent(
    { name, value = [""], onChange, disabled, readOnly, ...rest },
    ref
  ) {
    const { t } = useTranslation();
    const onAdd = () => onChange([...value, ""]);
    const onRemove = (index) => () => {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange(newValue);
    };

    const inputs = value.map((_, index) => {
      return (
        <InputWrapper
          key={index}
          data-qa-type="multiple-input"
          data-qa-index={index}
        >
          <Component
            ref={ref}
            {...rest}
            name={`${name}.${index}`}
            data-qa={`${name}_${index}`}
            disabled={disabled}
          />
          {value.length > 1 && (
            <RemoveFieldButton
              secondary
              data-qa={`remove_field_${index}`}
              onClick={onRemove(index)}
            >
              <FontAwesomeIcon icon={faMinusCircle} />
            </RemoveFieldButton>
          )}
          {index === value.length - 1 && (
            <AddFieldButton
              data-qa="add_field"
              onClick={onAdd}
              disabled={disabled || readOnly}
            >
              {t("Add Field")}
            </AddFieldButton>
          )}
        </InputWrapper>
      );
    });

    return (
      <MultiInputsWrapper>
        <FieldsWrapper>{inputs}</FieldsWrapper>
      </MultiInputsWrapper>
    );
  }

  FieldsComponent.displayName = `FieldsComponent(${Component.displayName})`;

  return React.forwardRef(FieldsComponent);
}

export default MultipleFields;
