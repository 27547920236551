import React from "react";
import { Radio } from "antd";

const RadioGroup = ({
  value,
  onChange,
  children,
  options,
  disabled,
  ...rest
}) => {
  function renderChildren() {
    return React.Children.map(children, (child) => {
      if (!child) {
        return null;
      }
      return React.cloneElement(child, {
        selected: value === child.props.value,
        onClick: !disabled && onChildClick(child.props),
      });
    });
  }

  const onChildClick = (childProps) => {
    if (!childProps.disabled) {
      return () => onChange(childProps.value);
    }
  };

  const onOptionClick = (e) => onChange(e.target.value);
  return (
    <div>
      {Array.isArray(options) ? (
        <Radio.Group
          value={value}
          onChange={onOptionClick}
          options={options}
          disabled={disabled}
          {...rest}
        />
      ) : (
        renderChildren()
      )}
    </div>
  );
};

export default RadioGroup;
