import ModalService from "services/modal";

export const SCAN_SETTINGS_MODULE = "scan-settings";
export const scanWarning = new ModalService("scan-warning");
export const scanLogDetails = new ModalService("scan-log-details");
export const SCAN_TYPES = {
  0: "kubebench",
  1: "kubehunter",
  2: "sonobuoy",
  kubebench: 0,
  kubehunter: 1,
  sonobuoy: 2,
};
