import React from "react";
import styled from "styled-components";
import history from "services/history";
import { useTranslation } from "react-i18next";
import noProjectsPath from "assets/images/login/no_projects.png";
import FullPageOverlay from "components/common/layouts/Page";
import { white } from "utils/constants/colors";

const ContentWrap = styled.div`
  height: 504px;
  width: 1088px;
  background: ${white};
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const NoProjectsContent = styled.div`
  padding: 50px 63px;
  position: relative;

  h1 {
    font-size: 38px;
    line-height: 48px;
    color: rgb(77, 74, 190);
    font-weight: 500;
    margin: 0;
  }

  p {
    font-size: 20px;
    line-height: 32px;
    margin: 10px 0;
  }
`;

const Environment = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
`;

export default function Error() {
  const { t } = useTranslation();
  const { message, errCode } = history?.getQuery();

  return (
    <FullPageOverlay>
      <ContentWrap>
        <Environment src={noProjectsPath} alt="error" />
        <NoProjectsContent>
          <h1>{errCode || "Ooops"}</h1>
          <br />
          <p>{message || t("An error has occured.")}</p>
          <a href="https://console.spectrocloud.com/auth">
            {t("Back to Console")}
          </a>
          <br />
          <a href="https://spectrocloud.com">{t("spectrocloud.com")}</a>
        </NoProjectsContent>
      </ContentWrap>
    </FullPageOverlay>
  );
}
