import React from "react";
import styled, { keyframes, css } from "styled-components";
import Loader from "components/ui/Loader";
import { STATUS_COLOR, white, lightMidGray } from "utils/constants/colors";

const shouldFlicker = (props) =>
  ["provisioning", "deleting", "importing"].includes(props.status);

const flickerAnimation = keyframes`
  0%   { opacity:1; }
  50%  { opacity:0.3; }
  100% { opacity:1; }
`;

const LoaderWrap = styled.div`
  display: inline-block;
  margin-left: 5px;
  svg {
    fill: ${white};
  }
`;

const Status = styled.div`
  display: inline-block;
  font-family: "Montserrat", Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: ${white};
  border-radius: 12px;
  padding: 6px 15px;
  background-color: ${(props) => STATUS_COLOR[props.status] || lightMidGray};

  ${(props) =>
    shouldFlicker(props) &&
    css`
      animation: ${flickerAnimation} 1s infinite;
    `}
`;

const StatusCard = ({ status, isLoading, customText }) => {
  if (!status) {
    return null;
  }

  function renderLoader() {
    if (!isLoading) {
      return null;
    }

    return (
      <LoaderWrap>
        <Loader small />
      </LoaderWrap>
    );
  }

  return (
    <Status status={status.toLowerCase()} data-qa="status">
      {customText || status} {renderLoader()}
    </Status>
  );
};

export default StatusCard;
